import React, { RefObject, useEffect, useState } from 'react';
import Layout from './Layout';
import Error from '../../components/Error/Error';
import { Spinner } from '@fluentui/react'
import { validateToken, fetchWelcomeMessage } from "../../api/api";

let token: string | null = null;

/**
 * Login component
 */
function Login() {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [welcomeMessageLoading, setWelcomeMessageLoading] = useState(true);
    const [attendeeType, setAttendeeType] = useState("");
    const [isWelcomeMessages, setIsWelcomeMessages] = useState<any>(null);
    const [isHover, setisHover] = useState(false);
    useEffect(() => {
        /**
         * Fetches the token validation data and sets the authenticated state accordingly
         */
        async function fetchData() {
            try {
                const data = await validateToken();
                const attendeeTypeClaim = data.claims.find((claim: string) => claim.startsWith("attendeeType:"));
                const attendeeType = attendeeTypeClaim ? attendeeTypeClaim.split(":")[1].trim() : null;
                var registration = "";
                if(attendeeType.trim().toLowerCase().includes("digital") || attendeeType.trim().toLowerCase().includes("non-attending")){
                    registration = "digital";
                }
                else if(attendeeType.trim().toLowerCase().includes("anonymous")){
                    registration = "anonymous";
                }
                else{
                    registration = "in-person";
                }
                setAttendeeType(registration);
  
                if (data.message === 'Token is valid') {
                    setAuthenticated(true);
                }
            } catch (error) {
                return;
            } finally {
                setLoading(false);
            }
        }
        fetchData();
        const fetchDataFromApi = async () => {
            try {
                const result = await fetchWelcomeMessage();
                setIsWelcomeMessages(result);
            } catch (error) {
                return;
            } finally {
                setWelcomeMessageLoading(false);
            }
        };
        fetchDataFromApi();
    }, []);

    /**
     * Renders the spinner while the data is being loaded
     */
    if (loading || welcomeMessageLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div>
                    <Spinner label="Loading..." />
                </div>
            </div>
        );
    }

    const scroller = (chat: RefObject<HTMLDivElement>) => {
        if (isHover) return;
        if(!chat.current) return;
        const timeout=setTimeout(() => {
            chat.current?.scrollIntoView();
            setisHover(false);
        }, 500);
        return clearTimeout(timeout);
    };
    /**
     * Renders the Layout component if authenticated, else renders the Error component
     */
    return (
        
        <div>
            {authenticated ? (
                <Layout
                    attendeeType={attendeeType}
                    welcomeMessages={isWelcomeMessages}
                    scroller={scroller}
                />
            ) : (
                <Error />
            )}
        </div>
    );
}

export { token };

export default Login;